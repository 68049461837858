<template>
  <div>
    <div class="d-flex flex-nowrap align-items-center flex-column pb-4">
      <div class="text-center mb-2">
        <i class="fe fe-star font-size-40"></i>
      </div>
      <div class="text-center mb-2">
        <div class="font-weight-bold font-size-24 text-dark mb-1">David Beckham</div>
        <div class="font-size-18">8748-XXXX-1678-5416</div>
      </div>
    </div>
    <div class="text-center text-gray-4 pt-3">
      Expires at 03/22
    </div>
  </div>
</template>
<script>
export default {
  name: 'KitGeneral5v1',
}
</script>
